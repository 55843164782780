var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('page-title',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('tournament.winners')))]},proxy:true}])}),_c('div',{staticClass:"col-12"},[_c('b-tabs',{attrs:{"content-class":"pt-1","fill":""},model:{value:(_vm.groupID),callback:function ($$v) {_vm.groupID=$$v},expression:"groupID"}},_vm._l((_vm.tournamentGroups),function(groupTab,index){return _c('b-tab',{key:index,attrs:{"title":_vm.checkLang(groupTab.name)},on:{"click":function($event){return _vm.getWinners(groupTab.id)}}},[_c('div',{staticClass:"card"},[(_vm.requestPending)?_c('progress-linear'):_c('b-table',{staticClass:"news__table",attrs:{"fields":_vm.fields,"items":_vm.items,"responsive":true,"borderless":"","outlined":"","striped":""},scopedSlots:_vm._u([{key:"cell(id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.position)+" ")]}},{key:"cell(user_id)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.id)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.first_name + ' ' + item.user.last_name)+" ")]}},{key:"cell(score)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total_scores)+" ")]}},{key:"cell(phone)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.phone)+" ")]}},{key:"cell(statuses)",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticStyle:{"width":"220px"},attrs:{"options":_vm.statusOptions,"placeholder":_vm.$t('choose.status'),"searchable":false},on:{"input":function($event){return _vm.updateWinnerStatus({
                                        id: item.id,
                                        status: $event
                                    })}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"cell(gift)",fn:function(ref){
                                    var item = ref.item;
return [(item.gift && item.gift.product)?_c('span',[_vm._v(" "+_vm._s(_vm.checkLang(item.gift.product.name))+" ")]):_c('span')]}},{key:"cell(images)",fn:function(ref){
                                    var item = ref.item;
return [_c('div',{staticClass:"table-img"},[(
                                        item.gift &&
                                        item.gift.product &&
                                        item.gift.product.images.length > 0
                                    )?_c('img',{attrs:{"src":item.gift.product.images[0].path}}):_c('img',{attrs:{"src":require("../../../assets/images/icons/no-photo.png")}})])]}}],null,true)})],1),_c('b-row',[_c('b-col',{staticClass:"offset-3",attrs:{"cols":"9"}},[(_vm.showPagination)?_c('b-pagination',{staticClass:"float-right mr-4 mb-0",attrs:{"per-page":_vm.pagination.perPage,"total-rows":_vm.pagination.totalItem,"first-number":"","last-number":""},model:{value:(_vm.pagination.current),callback:function ($$v) {_vm.$set(_vm.pagination, "current", $$v)},expression:"pagination.current"}}):_vm._e()],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }