<script>
import api from '@/services/api';
import PageTitle from '@/components/PageTitle';
import ProgressLinear from '@/components/ui/ProgressLinear/ProgressLinear';
import { BButton, BCol, BPagination, BRow, BTable } from 'bootstrap-vue';
import CRUDPageMixin from '@/mixins/CRUDPageMixin';
import vSelect from 'vue-select';
import {
    checkLang,
    generateIconColorToBoolean,
    generateIconToBoolean,
    updateObjectToNew
} from '@/util/helper';
import { MERCHANT_ID } from '@/constants/names';

export default {
    name: 'TournamentWinners',
    components: {
        ProgressLinear,
        PageTitle,
        vSelect,
        BButton,
        BTable,
        BCol,
        BRow,
        BPagination
    },
    mixins: [CRUDPageMixin],
    data() {
        return {
            items: [],
            statusOptions: [],
            tournamentGroups: [],
            groupID: null,
            dataForModal: {
                status: null
            },
            pagination: {
                current: 1,
                totalItem: '',
                perPage: 100
            },
            localLang: localStorage.getItem('lang'),
            requestPending: false
        };
    },

    watch: {
        '$route.query': {
            handler(query) {
                this.pagination.current = query.page || 1;
                this.getWinners(this.tournamentGroups[0].id);
            },
            deep: true
        },

        'pagination.current': {
            handler(page) {
                let newPage = page;
                if (this.pagination.current > this.pagination.totalItem) {
                    newPage = 1;
                }
                this.replaceRouter({
                    ...this.query,
                    page: newPage
                });
            }
        }
    },

    computed: {
        fields() {
            return [
                {
                    key: 'id',
                    label: '#'
                },
                {
                    key: 'name',
                    label: this.$t('titles.title')
                },
                {
                    key: 'user_id',
                    label: this.$t('ID')
                },
                {
                    key: 'score',
                    label: this.$t('titles.score')
                },
                {
                    key: 'phone',
                    label: this.$t('titles.phone_number')
                },
                {
                    key: 'images',
                    label: this.$t('titles.image')
                },
                {
                    key: 'gift',
                    label: this.$t('titles.gifts')
                },
                {
                    key: 'statuses',
                    label: this.$t('titles.status')
                },
                {
                    key: 'actions',
                    label: ''
                }
            ];
        },

        query() {
            return Object.assign({}, this.$route.query);
        },

        hasItems() {
            return this.items && this.items.length > 0;
        },

        showPagination() {
            return this.hasItems && !this.requestPending;
        }
    },

    async mounted() {
        try {
            this.requestPending = true;
            await this.getWinnerStatuses();
            await this.getTournamentGroups().then(() => {
                this.getWinners(this.tournamentGroups[0].id);
            });
        } finally {
            this.requestPending = false;
        }
    },

    methods: {
        checkLang,

        async getWinnerStatuses() {
            const body = {
                page: this.pagination.current,
                limit: 10
            };
            await api.tournament
                .fetchWinnerStatuses(body)
                .then((response) => {
                    this.statusOptions = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getTournamentGroups() {
            const body = {
                page: this.pagination.current,
                limit: this.pagination.perPage,
                tournament_id: parseInt(this.$route.params.tournament_id)
            };
            await api.tournament
                .fetchAllGroups(body)
                .then((response) => {
                    this.tournamentGroups = response.data.result;
                    this.tournamentGroups.sort(
                        (gItemPrev, gItemNext) =>
                            checkLang(gItemPrev.name).charCodeAt(0) -
                            checkLang(gItemNext.name).charCodeAt(0)
                    );
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        async getWinners(groupId) {
            try {
                this.requestPending = true;
                const body = {
                    page: this.pagination.current,
                    limit: this.pagination.perPage,
                    tournament_id: parseInt(this.$route.params.tournament_id),
                    merchant_id: parseInt(MERCHANT_ID),
                    group_id: groupId
                };
                this.items = [];
                await api.tournament
                    .fetchWinners(body)
                    .then((response) => {
                        response.data.result.forEach((item) => {
                            this.items.push({
                                ...item,
                                status: this.statusOptions[item.status]
                            });
                        });
                        this.pagination = response.data.pagination;

                        if (this.pagination.current > this.pagination.totalItem) {
                            this.pagination.current = 1;
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } finally {
                this.requestPending = false;
            }
        },

        async updateWinnerStatus(body, config) {
            body.status = this.statusOptions.findIndex((sOpt) => sOpt === body.status);
            await api.tournament
                .updateWinnerStatuses(body, config)
                .then(() => {
                    this.getWinners(this.tournamentGroups[this.groupID].id);
                    this.$toast.success(this.$t('success.updated.title'));
                })
                .catch((e) => {
                    this.$toast.error(
                        e.response?.data?.message || e.message || this.$t('error.smth')
                    );
                });
        },

        async submitModal() {
            this.$refs.createUpdateModal.validate().then(async (success) => {
                if (success) {
                    let body = this.dataForModal;

                    const config = {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    };

                    try {
                        if (this.updatingItem) {
                            body.id = this.updatingItem.id;
                            await this.updateWinnerStatus(body, config);
                        }
                        // else {
                        //     await this.createLanguage(body, config);
                        // }

                        this.isOpenModal = false;
                    } catch (error) {
                        this.$toast.error(this.$t('error.smth'));
                    }
                }
            });
        },

        changePerPage(perPage) {
            this.newsParams = {
                perPage,
                page: 1
            };
            this.getWinners();
        },

        replaceRouter(query) {
            this.$router.replace({ query }).catch(() => {});
        },

        generateIconToBoolean,
        generateIconColorToBoolean,

        matchStatuses(status) {
            if (this.statusOptions.length) {
                return this.statusOptions[status];
            }
            return '';
        }
    }
};
</script>

<template>
    <div>
        <page-title>
            <template #title> {{ $t('tournament.winners') }}</template>
            <!--            <template v-if="hasAccess('cities', 'create')" #button>-->
            <!--                <b-button v-b-modal.createUpdateModal class="ml-auto" variant="success">-->
            <!--                    {{ $t('button.add') }}-->
            <!--                    <feather-icon icon="PlusIcon" />-->
            <!--                </b-button>-->
            <!--            </template>-->
        </page-title>

        <!--    GROUP TABS    -->
        <div class="col-12">
            <b-tabs v-model="groupID" content-class="pt-1" fill>
                <b-tab
                    v-for="(groupTab, index) in tournamentGroups"
                    :key="index"
                    :title="checkLang(groupTab.name)"
                    @click="getWinners(groupTab.id)"
                >
                    <!--  TABLE  -->
                    <div class="card">
                        <progress-linear v-if="requestPending" />
                        <b-table
                            v-else
                            :fields="fields"
                            :items="items"
                            :responsive="true"
                            borderless
                            class="news__table"
                            outlined
                            striped
                        >
                            <template #cell(id)="{ item }">
                                {{ item.position }}
                            </template>

                            <template #cell(user_id)="{ item }">
                                {{ item.user.id }}
                            </template>

                            <template #cell(name)="{ item }">
                                {{ item.user.first_name + ' ' + item.user.last_name }}
                            </template>

                            <template #cell(score)="{ item }">
                                {{ item.total_scores }}
                            </template>

                            <template #cell(phone)="{ item }">
                                {{ item.user.phone }}
                            </template>

                            <template #cell(statuses)="{ item }">
                                <v-select
                                    style="width: 220px"
                                    v-model="item.status"
                                    :options="statusOptions"
                                    :placeholder="$t('choose.status')"
                                    :searchable="false"
                                    @input="
                                        updateWinnerStatus({
                                            id: item.id,
                                            status: $event
                                        })
                                    "
                                />
                            </template>

                            <template #cell(gift)="{ item }">
                                <span v-if="item.gift && item.gift.product">
                                    {{ checkLang(item.gift.product.name) }}
                                </span>
                                <span v-else> </span>
                            </template>

                            <template #cell(images)="{ item }">
                                <div class="table-img">
                                    <img
                                        v-if="
                                            item.gift &&
                                            item.gift.product &&
                                            item.gift.product.images.length > 0
                                        "
                                        :src="item.gift.product.images[0].path"
                                    />
                                    <img v-else src="../../../assets/images/icons/no-photo.png" />
                                </div>
                            </template>
                        </b-table>
                    </div>

                    <!--  PAGINATION  -->
                    <b-row>
                        <b-col class="offset-3" cols="9">
                            <b-pagination
                                v-if="showPagination"
                                v-model="pagination.current"
                                :per-page="pagination.perPage"
                                :total-rows="pagination.totalItem"
                                class="float-right mr-4 mb-0"
                                first-number
                                last-number
                            />
                        </b-col>
                    </b-row>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

::v-deep .vs__dropdown-menu {
    min-height: auto;
    //height: 100px;
}

.validation__red {
    color: red;
}

.card-body {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100px;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100px;
            height: 100%;
            object-fit: cover;
        }
    }
}

.bordered__image-list {
    border: 2px dashed #999999;

    &_item {
        position: relative;
        width: 100%;
        height: 100px;

        &-close {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -5px;
            right: -5px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: #999999;
            color: #fff;
            cursor: pointer;
            font-size: 12px;
        }

        & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.table-img {
    width: 60px;
    height: 60px;
    background-color: #999999;
    border-radius: 0.25rem;

    & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.25rem;
    }
}
</style>
